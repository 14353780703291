import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Summary`}</h2>
    <p>{`This document is an update to the BD Pyxis ES System release notes.
BD is pleased to announce the launch of the BD Pyxis ES System 1.8 Limited Commercial Release (LCR).`}</p>
    <br />
    <h2>{`Release components`}</h2>
    <p>{`The BD Pyxis ES System 1.8 comprises the software applications listed below.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Components" mdxType="Tab">
        <h4>{`Component Name`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Version`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Enterprise Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`5.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Anesthesia Station ES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CII Safe ES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis MedStation ES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`4.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Check (Web)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CUBIE System`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.4`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Enterprise Server Reporting`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.26`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD HealthSight Viewer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2.7`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Med Link Queue & Waste`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.15`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis MedStation ES Tower`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2.5`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Track & Deliver`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`1.6`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Data Agent`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`2.14`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Hardware Test Application`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`5.7`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
    <h2>{`Upgrade path`}</h2>
    <p>{`The system must be on version 1.4.4.x, 1.5.2.1, 1.6.1, 1.6.1 FIPs, 1.7.3, or 1.7.4 before applying system release 1.8.`}</p>
    <h2>{`Browser compatibility`}</h2>
    <p>{`Users must use supported Chrome or Microsoft Edge browsers. (The BD Pyxis ES System has been tested on Chrome version 80 and Edge version 81.)`}</p>
    <h2>{`Cybersecurity Trust Center`}</h2>
    <p>{`Visit the BD Cybersecurity Trust Center to:`}</p>
    <ul>
      <li parentName="ul">{`Access product security bulletins and patch notifications`}</li>
      <li parentName="ul">{`Report a product security concern, incident or vulnerability`}</li>
      <li parentName="ul">{`Request Product Security White Papers and SOC2+ reports`}</li>
      <li parentName="ul">{`Access independently audited cybersecurity certifications`}</li>
      <li parentName="ul">{`Download the latest BD Cybersecurity Annual Report
Site: `}<inlineCode parentName="li">{`https://bd.com/cybersecurity`}</inlineCode>{`
Email: `}<a parentName="li" {...{
          "href": "mailto:cybersecurity@bd.com"
        }}>{`cybersecurity@bd.com`}</a></li>
    </ul>
    <h2>{`New System Release 1.8 enhancements`}</h2>
    <ol>
      <li parentName="ol">{`Support for external user authentication card reader.`}</li>
      <li parentName="ol">{`Display Software UDI on About screen`}</li>
      <li parentName="ol">{`User Role Assignment through Message—Creating user accounts from a request`}</li>
      <li parentName="ol">{`Install/Upgrade Efficiency—Reducing System Validation and Improving Integration Quality`}</li>
      <li parentName="ol">{`Oracle Cerner SSO for BD Pyxis Med Link`}</li>
    </ol>
    <h2>{`BD Pyxis ES Reporting v1.20.x to v1.26.x`}</h2>
    <p>{`The BD Pyxis ES System 1.8 release includes enhancements to BD Pyxis ES Reporting to accommodate BD Pyxis CII Safe ES enhancements, as well as updates to existing reports.`}</p>
    <h2>{`New functionality for BD Pyxis ES System and BD Pyxis CII Safe ES Reporting`}</h2>
    <ol>
      <li parentName="ol">{`New ADM Controlled Substance Audit Report under Activity tab.`}</li>
    </ol>
    <p>{`##Guides and training
Look for the latest versions of the following guides and training at your respective access points.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Guides and training" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Guide Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`DIR Number/Material Number`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Enterprise Server User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418518`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Anesthesia Station ES User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418515`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis MedStationTM ES User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418513`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis ES System How It Works Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418508`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CII Safe ES Equipment Specifications Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418588`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis ES Platform System Validation Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418516`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis ES System Deployment Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418506`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Enterprise Server Archiving User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418503`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis ES Platform Interface Specifications Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418510`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Check User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000356487`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CII Safe ES Station User Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418517`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CII Safe ES How It Works Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000402879`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis CII Safe ES Special Sheets Booklet`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000356491`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis ES System and BD PyxisTM CII Safe ES Reporting Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000418511`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis MedStationTM ES Universal 601 Safety Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000334240`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD Pyxis Supply Technologies Universal 601 Safety Guide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`10000145676`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
    <h2>{`Resolved issues for BD Pyxis ES System`}</h2>
    <p>{`The following tables list the previously open issues that have been resolved in this release.
The resolution of these issues helps to improve overall system stability, user efficiency, and medication safety features.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="BD Pyxis Enterprise Server" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Resolved issue`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1289950`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`LCR 1.7.3 - Station Fails to Upload Data to Server due to Large Amount of Deletes in Change Tracking Table.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1296265`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Discharged Encounter that has Temp unit but no assigned unit is actionable at the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1296733`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`CII Safe ES 1.7.3 - Multiple options set to “No’ instead of “Mixed” when performing formulary Global Edit.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1296990`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`External Refill Request Expiration text field is editable when External Refill Worklist is false on Add/Edit facility page.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297194`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Multiple error messages are displayed if the user enters an incorrect value for External Refill Worklist Expiration.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297254`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`External Refill Request Expiration text field is editable by admin/local user.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297788`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BioID Failover Authentication Mode GUI label text is not consistent w.r.t the other field label under facility.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298687`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ES 1.8 - Sync 2.0: When encounter is transferred back to the previous facility and there were changes in the order properties while in the other facility, these changes are not synced to the current transferred facility, encounter does not have an updated order.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298874`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`System prompts a notification when user change Card+PIN login method for CIISafe ES to different login method after performing full sync and then again changing back to Card+PIN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298934`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Unplanned CriticalOverride - Spec 403620 and 403609 needs to be combined to define DOWNDELAY internal code in one spec.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1299033`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When order is sent from CCE and put on hold on future time, server UI shows hold status immediately if the web browser has a different time zone to the facility, however device is behaving correctly and does not put order on hol`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1300463`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays error for “Attention Notice Critical Display”, “Med View Dose Request Duration”, “Med View dashboard New Dose Request Duration notices type” when server is configured with Attention Notices.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1300476`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system removes Card+Pin Failover Authentication Mode and Card + PIN Failover Login in Absence of an ID Card fields from facility settings when system give error for attention notices type.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1300808`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays confirmation pop up to user when user edit multiple user account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301549`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Dispensing System: System displays “CIISafe retention days” field warning message to user when “CIISafe Deployment flag” is not enabled while adding Dispensing system.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301564`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display the default value for “CIISafe retention (days)” when adding Dispensing system it is shown as Empty.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301606`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display “Card Exempt login mode” default option as selected for already deployed devices.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301884`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays warning message “Card + PIN login mode is not supported for some of the selected devices.” when user edit different device type together (CIISafe/PAS/Medstation).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1302058`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system authenticates the user to witness with Card+pin for the receive workflow if user does not have required permissions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1302060`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system authenticates the user to witness with Card+pin for the unload workflow if user does not have required permissions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1302084`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays the “User is not exempt’” error meesage to the user, if witness with Card+pin user goes to failover mode for the receive workflow and witness user doesnot have required permissions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1302460`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`During witness if we give the witness with the login user (Card + Pin), system shows an error to the user and cancel button is non-functioning till the time we remove the card.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1302987`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not create authentication event in event table for an invalid card if User does not click on OK.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303235`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not take User to next screen if User clicks cancel on ‘Enter PIN’ screen and on alternate witness failover authentication mode.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303289`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`CII Safe Retention (days) should have a default value.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303891`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Build version for 1.8.0 is not updated in About section.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303908`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system redirects user again to Add device page when user click on cancel button on add device screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304390`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DB upgrade to server 5.5 all reference data inserted into Core.AuthenticationFailureReason does not match data listed in spec.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304647`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`INTL 1.8i Global- text overflow under patient information screen for PLN application(Enhancement).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1305992`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Spec 1300612 needs to be updated to remove current record statement for the fingerprint exempt flag.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1306305`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Server Identity Verification Disabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1306828`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Facility - Cannot create a Facility when “CII Safe Deployment Flag” is disabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1307546`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ES 1.7.4(LCR) - False Discrepancy created due to working on the same pocket from both server and station sides.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1307570`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Transactions from 1.7.4 device were not synced to 1.6.1 devices.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1308235`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`FacilityItemSnapshot records are not getting inserted and old records ended outside of skip range.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1308741`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Column UseEquivalenciesFlag in Location.FacilitySnapshot is getting updated to 0 during upgrade from 1.4.4.1 or 1.5.2.1 to 1.8.0.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309053`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system deletes Bio metric template before 24 hours.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309231`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ES deletion of all devices/ Discharge of all patients or deletion of all formulary items may happen if select all=false and no key is selected.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309295`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not assign u.ser role to the AD user after the User conversion.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309635`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays unexpected server error for facility kits when user selecting facility Non- Stat kits from the server.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309924`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Card Reader Not Detected flag in the authentication event table is getting updated to 0 when Card Reader is Disconnected and User logs in via failover login mode.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310848`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays error when user performs edit on facility after server upgrade through in place and migration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310949`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Database bloat for Medstations with purge deletion errors when the exception is not handeled properly.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311585`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Incorrect value is synced from server to device for Location.FacilitySnapshot.UserAuthenticationRequestDuration2Amount.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312196`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SSL settings of pyxis identity server is disabled by default on ES server and system doesn’t authenticate the PIV card on device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312774`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The device is not using configured value to timeout and use cache credential to login, duration of timeout is random when network is slow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312991`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Global Edit - List of selected Area Roles should be displayed on the Confirmation Dialog Container when adding Area Roles.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312993`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Global Edit - “No changes were detected” when editing Area Roles.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312994`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`“No changes were detected” error occurs when editing Remote Waste CDC on Areas page.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313315`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Dosage form entries are not in ascending order in dosage dropdown when creating a new medication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313318`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`“An error occurred while processing your request” message is displayed when navigating to package status on Package status screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1314110`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SSL settings of pyxis identity server are enabled and set to accept client certificates by default on ES server and impacts integrating products launching.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="BD Pyxis MedStation ES" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`83637`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Patient should be displayed at original station until transfer delay time elapsed after the patient is transferred to new facility.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297497`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays “unable to authenticate” message when user initiates user conversion from device side.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298664`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The newly created patient is not displayed on the MedStation ES.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298929`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not change order status as “On hold” on station UI when server and device are in different time zones.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301236`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station prompts Object Reference error when at server “BD Dispensing Device Service” is stopped and at device user clicks on “Data Synchronization”.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301872`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Local user is granted duplicate areas for roles that do not have areas already assigned after changing their password at the station.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303893`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system prompts “Object Reference error” at the device when performing full download at the station and during this time data sync service is stopped at the server.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays “Card PIN exempt mode” screen to user when witnessing user does not have appropriate permission.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304365`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Login mode: the system does not allow “BioID exempt” user to proceed with login workflow when user id scan code is required for “Bio ID exempt user only” and scanner is not detected.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309111`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display Recent Authentication Information when user logged into the Device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309164`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system cancels the witness workflow if user clicks on OK button on message “Witness cannot be same as the signed-in user” during inventory count workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309295`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not assign user role to the AD user after the User conversion.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309389`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system truncates the Quantity field and Dosage form text for a med on Dispense medication screen workflow If medication has long UOM and Dosage form.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309877`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AD user and support users are not able to login on device and getting “Unable to authenticate” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309909`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system truncates the Patient ID of the patient in the expanded view.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309937`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Device is not displaying PIN prompt for the Finnish cards in the App mode.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310367`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system searches for medication after 3 characters when Search character length is set as 5 in facility setting.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310532`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not populate “ExternalInventoryCountRequestKey” field in Tx.ItemTransactionSnapshot with corresponding value from “Tx.ExternalInventoryCountRequest” for the external inventory count request.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310542`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system doesn’t populate “ExternalRefillRequestKey” field in Tx.ItemTransactionSnapshot with corresponding value from ‘Tx.ExternalRefillRequest’ table for the external refill request.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310616`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Card reader stops reading the cards intermittently, the system has to be rebooted in order to continue with card+Pinlogin.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310809`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIN prompt is coming at the med app whenever card comes in the contact with the card reader (not inserted- contactless feature of the card reader).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310948`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Device fell off server list after name change.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310949`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Maintenance Service - database bloat for MedStations with purge deletion errors when the exception is not handled properly.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311000`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`About screen contents are displayed as copyright 2019 instead of 2023.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays return quantity field as non-editable when user clicks on Return to Internal bin button on return to stock screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system bypass witness workflow when user return med to Internal med without prior remove.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311798`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow BD Support user to login to the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312094`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not include User ID and User Account Key in the Authentication Event table for a failed authentication using card + PIN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312358`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIV card login authentication is not working on the devices when PIV Key installer is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Finnish card log in authentication is not working on the devices when Digisign Installer to support OmniKey 5422 card reader is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312663`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BioID is not working on device and is displaying “BioID device required Maintenance” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313397`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Smartcard is not using the authentication timeout value configured at the facility.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313486`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Timeout - When Cabinet Controller connection is slow and user tries to log in with BioID, finger print scan screen freezes and selecting cancel button crashes the medication app (medication app not responding error).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1314578`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display appropriate message “Unable to Authenticate”, when we provide witness with the deleted PIV card user while performing inventory count workflow.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="BD Pyxis Integrated Main with CUBIE" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1289950`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station Fails to Upload Data to server due to Large Amount of Deletes in Change Tracking table.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1298929`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not change order status as “On hold” on station UI when the server and device are in different time zone.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301236`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station prompts Object Reference error when at server “BD Dispensing Device Service” is stopped and user clicks on “Data Synchronization” at the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301872`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Local user is granted duplicate areas for roles that do not have areas already assigned after changing their password at the station.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1303893`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system prompts “Object Reference error” at device when performing full download at station and during this time data sync service is stopped at the server.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Witness: System displays “Card PIN exempt mode” screen to user when witnessing user does not have appropriate permission.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304365`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow “BioID exempt” user to proceed with login workflow when user id scan code is required for “BioID exempt user only” and scanner is not detected.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309111`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display Recent Authentication Information when user logged into the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309164`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system cancels the witness workflow if user clicks the OK button on message “Witness cannot be same as the signed-in user” during inventory count workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309295`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not assign user role to the AD user after the User conversion.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309389`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system truncates the Quantity field and Dosage form text for a medication on Dispense medication screen workflow if medication has long UOM and Dosage form.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309877`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AD user and support users are not able to login on device and getting “Unable to authenticate” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309909`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system truncates the Patient ID of the patient in the expanded view.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310367`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Assign and load: the system searches for medication after 3 characters when Search character length is set as 5 in facility setting.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310532`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`External Inventory Count Request - the system does not populate “ExternalInventoryCountRequestKey” field in Tx.ItemTransactionSnapshot with corresponding value from “Tx.ExternalInventoryCountRequest” for the external inventory count request`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310542`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`External Refill Request - The system does not populate “ExternalRefillRequestKey” field in Tx.ItemTransactionSnapshot with corresponding value from “Tx.ExternalRefillRequest” table for the external refill. request`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310616`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Card reader intermittently stops reading the cards, the system has to be rebooted in order to continue with card+PIN login.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310809`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIN prompt is coming at the medication app whenever the card comes in the contact with the card reader (not inserted- contactless feature of the card reader).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311000`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`About screen contents are displayed as copyright 2019 instead of 2023.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Return: the system displays return quantity field as non-editable when user clicks Return to Internal bin button on return to stock screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Return: the system bypasses witness workflow when user returns medication to Internal medication without prior remove.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311798`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow BD Support user to log in to the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312094`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not include User ID and User Account Key in the Authentication Event table for a failed authentication using card + PIN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312358`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIV card login authentication is not working on the devices when PIV Key installer is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Finnish card login authentication is not working on the devices when Digisign Installer to support OmneiKey 5422 card reader is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312663`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BioID is not working on device and is displaying “BioID device required Maintenance” error.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="BD Pyxis Anesthesia Station ES" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1289950`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station Fails to Upload Data to Server due to Large Amount of Deletes in Change Tracking Table.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297497`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays “unable to authenticate” message when user initiates user conversion from device side.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301236`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station prompts Object Reference error when at server “BD Dispensing Device Service” is stopped and at device user clicks on “Data Synchronization”.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301872`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Local user is granted duplicate areas for roles that do not have already assigned areas assigned after changing their password at the station.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system display “Card PIN exempt mode” screen to user when witnessing user does not have appropriate permission.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304365`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Login mode: System does not allow “Bio id exempt” user to proceed with login workflow when user id scan code is required for “BIO ID exempt user only” and scanner is not detected.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309111`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display Recent Authentication Information when user logged into the Device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309164`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system cancels the witness workflow if user Clicks on OK button on message “Witness cannot be same as the signed-in user” during inventory count workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309295`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not assign user role to the AD user after the User conversion.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309362`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system authenticates the user to witness with Card+pin for Transfer to User workflow if user does not have required permissions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309877`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AD user and support users are not able to login on device and getting “Unable to authenticate” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310609`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Finnish Card+PIN System is caching user’s pin at the device intermittently.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310616`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Intermittently card reader stops reading the cards, system has to be rebooted in order to continue with card+Pin login.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310809`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIN prompt is coming at the medication app whenever card comes in the contact with the card reader (not inserted- contactless feature of the card reader).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310948`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Config Utility - Device fell off server list after name change.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310949`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Maintenance Service - database bloat for Medstations with purge deletion errors when the exception is not handeled properly.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311000`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`About screen contents are displayed as copyright 2019 instead of 2023.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311798`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow BD Support user to login to the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312094`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not include User ID and User Account Key in the Authentication Event table for a failed authentication using card + Pin.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312358`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIV card login authentication is not working on the devices when PIV Key installer is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Finnish card login authentication is not working on the devices when Digisign Installer to support OmneiKey 5422 card reader is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312663`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BioID is not working on device and is displaying ‘Bio ID device required Maintenance’ error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313397`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Smartcard is not using the authentication timeout value configured at the facility.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1314578`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display appropriate message “Unable to Authenticate”, when we provide witness with the deleted PIV card user while performing inventory count workflow.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="BD Pyxis CII Safe ES" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1289950`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station Fails to Upload Data to Server due to Large Amount of Deletes in Change Tracking Table.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1297497`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays “unable to authenticate” message when user initiates user conversion from device side.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301236`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The station prompts Object Reference error when at server “BD Dispensing Device Service” is stopped and at device user clicks on “Data Synchronization”.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1301872`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Local user is granted duplicate areas for roles that do not already have assigned areas assigned after changing their password at the station.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304361`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays “Card PIN exempt mode” screen to user when witnessing user does not have appropriate permission.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304365`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow “BioID exempt” user to proceed with login workflow when user ID scan code is required for “BioID exempt user only” and scanner is not detected.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304483`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display header message on witness prompt screen when BioID is failed and user gets prompted to User ID+Password screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1304580`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not take user back to workflow screen if user clicks on Cancel button while giving witness during inventory count workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309111`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display Recent Authentication Information when user logged into the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309164`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system cancels the witness workflow if user clicks on OK button on message “Witness cannot be same as the signed-in user” during inventory count workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309295`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not assign user role to the AD user after the User conversion.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309547`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Receive workflow is not completing properly displaying error message after closing drawer during completion of receive workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1309877`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AD user and support users are not able to login on device and getting “Unable to authenticate” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310616`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Intermittently card reader stops reading the cards, the system has to be rebooted in order to continue with card+PIN login.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310809`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIN prompt is coming at the medication app whenever card comes in contact with the card reader (not inserted- contactless feature of the card reader).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310948`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Station Config Utility - Device fell off server list after name change.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1310949`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Maintenance Service - database bloat for Medstations with purge deletion errors when the exception is not handled properly.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311000`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`About screen contents are displayed as copyright 2019 instead of 2023.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311358`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system displays exception to user when user gets menu time-out on witness pop-up while performing empty and count destruction bin witness workflow.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1311798`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not allow BD Support user to login to the device.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312094`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not include User ID and User Account Key in the Authentication Event table for a failed authentication using card + PIN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312358`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`PIV card login authentication is not working on the devices when PIV Key installer is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312359`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Finnish card login authentication is not working on the devices when Digisign Installer to support OmniKey 5422 card reader is not installed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1312663`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BioID is not working on device and is displaying “BioID device required Maintenance” error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1313397`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Smartcard is not using the authentication timeout value configured at the facility.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1314578`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The system does not display appropiate message “Unable to Authenticate” when we provide witness with the deleted PIV card user while performing inventory count workflow.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="BD Pyxis ES Reporting" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1415987`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`All Device Events report “Transfer from patient” transaction type does not show patient name.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1433201`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`CII Safe ES Sell Report does not display Buyer CS License in filters.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1439640`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Getting error in ESR_Assets while migrating.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1439642`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Getting error in ESR while migrating.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1443843`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Only new PIS status is displayed after 24 hours for CII Safe Device Inventory Report.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1451027`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`CII Safe ES Total Par Days Reorder Report is giving unexpected error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`1464339`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Destruction Bin filter on CII Safe ES Device Inventory report displays “No Data Found”.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
    <h2>{`Support`}</h2>
    <p>{`For more information, call the BD Technical Support Center for Pyxis products (TSC) at 800.727.6102 from North America.
From all other locations, call 858.617.2000, or contact the TSC using your country-specific phone number (where available).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      